/**
 * svelte store wrapper around redux bundler
 *
 * this wrapper exposes three consts
 *
 * store - a svelte store adapter
 *
 * reduxStore - redux store
 *
 * select function that can be used
 * to select state from redux
 *
 */
import { readable } from 'svelte/store'
import createStore from './bundles'
import cache from './utils/cache'

const reduxstore = createStore({})

export const store = readable(reduxstore.selectAll(), function (set) {
  reduxstore.subscribe(() => {
    set(reduxstore.selectAll())
  })
})

export const action =
  (name) =>
  (...args) => {
    return reduxstore[name](...args)
  }

cache.getAll().then((initialData) => {
  if (('app' in initialData)) {
    reduxstore.doSetAccountAndCitizen(
      initialData.app?.account,
      initialData.app?.citizen
    )

    if (initialData.auth) {
      reduxstore.dispatch({
        type: 'LOGIN_SUCCESSFUL',
        payload: initialData.auth,
      })
      reduxstore.doUpdateUrl('/preferences')
    }
  }
})
