import sha1 from 'sha1'

const [
    GET_CITIZEN_STARTED,
    GET_CITIZEN_SUCCESSFUL,
    GET_CITIZEN_ERROR,
    PUT_CITIZEN_STARTED,
    PUT_CITIZEN_ERROR,
    PUT_CITIZEN_SUCCESS,
] = [
    'GET_CITIZEN_STARTED',
    'GET_CITIZEN_SUCCESSFUL',
    'GET_CITIZEN_ERROR',
    'PUT_CITIZEN_STARTED',
    'PUT_CITIZEN_ERROR',
    'PUT_CITIZEN_SUCCESS',
]

const initialState = {
    loading: false,
    data: {
        type: 'citizen',
        account: '',
        citizen: '',
        _id: '',
        categories: [],
        language: 'english',
        dateCreated: new Date().toISOString(),
    },
}

export default {
    name: 'citizen',
    doPutCitizen(doc) {
        return async ({ dispatch, store, apiUrl }) => {
            dispatch({ type: PUT_CITIZEN_STARTED })
            const accessToken = store.selectAccessToken()
            const url = apiUrl()
            doc.account = store.selectAccountNo()
            doc.citizen = store.selectCitizenNo()
            doc._id = sha1(doc.citizen)
            const result = await fetch(url + `/citizens/${doc._id}`, {
                method: 'PUT',
                body: JSON.stringify(doc),
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${accessToken}`,
                },
            }).then(res => res.json())

            if (result.ok) {
                doc._rev = result.rev
                dispatch({ type: 'PUT_CITIZEN_SUCCESS', payload: doc })
            } else {
                dispatch({
                    type: 'PUT_CITIZEN_ERROR',
                    payload: 'could not save preferences',
                })
            }

            return Promise.resolve({ ok: result.ok })
        }
    },
    doGetCitizen(id) {
        return ({ dispatch, store, apiUrl }) => {
            dispatch({ type: GET_CITIZEN_STARTED })
            const accessToken = store.selectAccessToken()
            const url = apiUrl()
            if (!url) {
                dispatch({
                    type: GET_CITIZEN_ERROR,
                    payload: new Error('apiUrl is not set'),
                })
                return Promise.resolve(false)
            }
            if (!accessToken) {
                dispatch({
                    type: GET_CITIZEN_ERROR,
                    payload: new Error('access token required!'),
                })
                return Promise.resolve(false)
            }

            return fetch(url + `/citizens/${sha1(id)}`, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${accessToken}`,
                },
            })
                .then(res => res.json())
                .then(citizen => {
                    if (citizen.error === 'not_found') {
                        return dispatch({
                            type: GET_CITIZEN_SUCCESSFUL,
                            payload: initialState.data,
                        })
                    }
                    dispatch({ type: GET_CITIZEN_SUCCESSFUL, payload: citizen })
                    return true
                })
                .catch(err => {
                    dispatch({ type: GET_CITIZEN_ERROR, payload: err })
                    return false
                })
        }
    },
    selectCitizenLoading(state) {
        return state.citizen.loading
    },
    selectCitizen(state) {
        return state.citizen.data
    },
    reducer(state = initialState, { type, payload }) {
        if (equals(type, GET_CITIZEN_STARTED)) {
            return merge(state, { loading: true })
        }
        if (equals(type, GET_CITIZEN_SUCCESSFUL)) {
            return merge(state, { loading: false, data: payload })
        }
        if (equals(type, GET_CITIZEN_ERROR)) {
            return merge(state, { loading: false, data: null, error: payload })
        }
        if (equals(type, PUT_CITIZEN_STARTED)) {
            return merge(state, { saving: true })
        }
        if (equals(type, PUT_CITIZEN_SUCCESS)) {
            return merge(state, { saving: false, data: payload })
        }
        if (equals(type, PUT_CITIZEN_ERROR)) {
            return merge(state, { saving: false, error: payload })
        }
        return state
    },
}

function merge(a, b) {
    return Object.assign({}, a, b)
}

function equals(a, b) {
    return a === b
}
