<script>
    import { getContext, onMount } from 'svelte'
    const action = getContext('action')
    const store = getContext('store')

    const [READY, ERROR] = ['READY', 'ERROR']

    let state = READY
    $: error = $store.authError

    onMount(async () => {
        const loggedIn = await action('doHandleAuthentication')()
        if (!loggedIn) {
            state = ERROR

            return
        }
        action('doUpdateUrl')('/preferences')
    })
</script>

<section class="section">
    <div class="container">
        {#if state === ERROR}
            <div class="box">
                <div class="notification is-danger">
                    <h1 class="title">
                        An Error has occured while processing your login.
                    </h1>
                </div>
                <p>Error: {error}</p>
                <p>
                    Please contact
                    <a href="mailto:support@citibot.io">support@citibot.io</a>
                </p>
            </div>
        {:else}
            <div>Logging in to citibot...</div>
        {/if}
    </div>
</section>
