<script>
  import { getContext, onMount } from 'svelte'
  import i18n from '../lib/i18n.json'
  import { includes, isEmpty, keys } from 'ramda'

  const store = getContext('store')
  const action = getContext('action')
  let lang = 'en'
  let selectHelp = t('select-help')
  let categoriesHelp = t('categories-help')
  let submitButton = t('submit-button')
  let selectButton = t('select-button')
  let items
  let citizen

  const [READY, ERROR, LOADING] = ['READY', 'ERROR', 'LOADING']

  let componentState = LOADING

  $: account = $store.account
  $: citizen = $store.citizen
  $: categories = $store.categories
  $: items = categories[citizen.language] ? categories[citizen.language] : null

  $: {
    if (citizen.language === 'spanish') {
      lang = 'es'
    } else {
      lang = 'en'
    }
    selectHelp = t('select-help')
    categoriesHelp = t('categories-help')
    submitButton = t('submit-button')
    selectButton = t('select-button')
  }

  onMount(async () => {
    let citizenRes = await action('doGetCitizen')($store.citizenNo)
    let accountRes = await action('doGetAccount')($store.accountNo)
    if (!citizenRes || !accountRes) {
      // componentState = ERROR

      return
    }

    componentState = READY
    console.log('Items', items)
  })

  function selectAll() {
    citizen.categories = items.map(prop('key'))
  }

  async function submit() {
    const doc = merge(citizen, { _id: '1' + $store.citizenNo })

    const result = await action('doPutCitizen')(doc)
    if (!result) {
      console.log('handle error')
    }
    action('doUpdateUrl')('/thanks')
  }

  function merge(...args) {
    return Object.assign({}, ...args)
  }

  function prop(v) {
    return function (obj) {
      return obj[v]
    }
  }

  function t(key) {
    return i18n[lang][key]
  }
</script>

<section>
  {#if componentState === LOADING}
    <main class="center">
      <div class="content">
        <img src="/loading.svg" alt="loading..." />
      </div>
    </main>
  {:else}
    <header class="navbar is-primary center">
      <div class="navbar-brand center">
        <h1 class="title">Notification Preferences</h1>
      </div>
    </header>
    <main class="center">
      {#if account}
        <div class="box">
          <h1 class="title has-text-primary">
            {account.description} Preferences
          </h1>

          {#if !isEmpty(items) && !isEmpty(categories)}
            {#if account.notifyData.citizenPreferenceText}
              <p>
                {account.description}
                {account.notifyData.citizenPreferenceText}
              </p>
            {:else}
              <p>
                {account.description} can notify you of events and news based on
                topics. You can check each topic you would like to be notified about,
                or check the Select All box to recieve notifications on all categories
              </p>
            {/if}
            {#if !includes('spanish', keys(account.notifyData.categories))}
              <hr />
              <div style="text-align: right;">
                <button class="button is-primary" on:click={selectAll}>
                  {selectButton}
                </button>
              </div>
              <label for="preferences"> Preferences </label>
            {:else}
              <hr />
              <div class="language-control">
                <div class="language-content">
                  <div class="select">
                    <select id="lang2" bind:value={citizen.language}>
                      <option value="english">English</option>
                      <option value="spanish">Spanish</option>
                    </select>
                  </div>
                  <label for="lang2">
                    Select a language/Selecciona un idioma:
                  </label>
                  <p id="help">{selectHelp}</p>
                </div>
              </div>
              <hr />
              <div style="text-align: right;">
                <button class="button is-primary" on:click={selectAll}>
                  {selectButton}
                </button>
              </div>
              <label for="preferences"> Preferences/Preferencias </label>
            {/if}
            <ul id="preferences">
              {#each items as item}
                <li>
                  <label for="categories" class="checkbox">
                    <input
                      type="checkbox"
                      bind:group={citizen.categories}
                      value={item.key}
                    />
                    <h2 id="categories">{item.title}</h2>
                  </label>
                </li>
              {/each}
            </ul>
          {:else if !includes('spanish', keys(account.notifyData.categories))}
            {#if account.notifyData.citizenPreferenceText}
              <p>
                {account.description}
                {account.notifyData.citizenPreferenceText}
              </p>
            {:else}
              <p>
                {account.description} can notify you of events and news. Please click
                submit to sign up.
              </p>
            {/if}
            <hr />
          {:else}
            {#if account.notifyData.citizenPreferenceText}
              <p>
                {account.description}
                {account.notifyData.citizenPreferenceText}
              </p>
            {:else}
              <p>
                {account.description} can notify you of events and news. Please select
                your prefered language and hit submit to sign up.
              </p>
            {/if}
            <hr />
            <div class="language-control">
              <div class="language-content">
                <div class="select">
                  <select id="lang2" bind:value={citizen.language}>
                    <option value="english">English</option>
                    <option value="spanish">Spanish</option>
                  </select>
                </div>
                <label for="lang2">
                  Select a language/Selecciona un idioma:
                </label>
                <p id="help">{selectHelp}</p>
              </div>
            </div>
            <hr />
          {/if}
        </div>
        <button class="button is-primary" type="button" on:click={submit}>
          {submitButton}
        </button>
      {/if}
    </main>
    <footer class="center">
      <p>All Rights Reserved. &copy; Citibot, LLC 2020</p>
    </footer>
  {/if}
</section>

<style>
  .language-control {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .select {
    float: right;
  }
  li {
    padding: 16px;
  }
  .checkbox {
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: flex-start;
  }
  .checkbox > input {
    margin-right: 16px;
    transform: scale(2);
  }
  section {
    height: 100%;
    display: grid;
    grid-template-areas:
      'header'
      'main'
      'footer';

    grid-template-rows: 72px auto 48px;
  }

  header {
    grid-area: header;
  }

  header > div {
    height: 100%;
  }
  .title {
    color: white;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  main {
    grid-area: main;
    display: flex;
    flex-direction: column;
  }

  .box {
    max-width: 650px;
    width: 100%;
  }

  footer {
    grid-area: footer;
  }
</style>
