// app bundle
//
// app bundle will contain the accessToken once fetch from a client credentials server.
import cache from '../utils/cache'

export default {
    name: 'app',

    getExtraArgs(store) {
        return {
            apiUrl() {
                return process.env.API
            },
        }
    },
    doSetAccountAndCitizen(account, citizen) {
        return ({ dispatch }) => {
            dispatch({ type: 'APP_SET_STATE', payload: { account, citizen } })
            return Promise.resolve(true)
        }
    },
    doClearCache() {
        return ({ dispatch }) =>
            new Promise((resolve, reject) => {
                // auth0.logout()
                dispatch({
                    type: 'APP_CLEAR_CACHE',
                    payload: { cacheCleared: true },
                })
                resolve(cache.clear())
            })
    },
    selectAccountNo(state) {
        return state.app.account
    },
    selectCitizenNo(state) {
        return state.app.citizen
    },
    reducer(state = { account: '', citizen: '' }, { type, payload }) {
        if (equals(type, 'APP_SET_STATE')) {
            return merge(state, payload)
        }
        return state
    },
    persistActions: ['APP_SET_STATE'],
}

// pure functions
function equals(a, b) {
    return a === b
}
function merge(...args) {
    return Object.assign({}, ...args)
}
