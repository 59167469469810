import App from './App.svelte'

import * as Sentry from '@sentry/svelte'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: 'https://398136ec5b04414a96c616a2aaf845ac@sentry.io/1463622',
  integrations: [new BrowserTracing()],
})

if (/localhost/.test(window.location.href)) {
  window.localStorage.setItem('debug', 'true')
}

const app = new App({
  target: document.body,
})

export default app
