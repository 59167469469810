// # Citibot Auth Bundle
//
// actions:
//
//   doLogin
//   doAuthenticate
//   doLogout
//
//
// selectors:
//
//   selectUser
//   selectAuthenticated
//
import { WebAuth } from 'auth0-js'

let auth0 = null
let tokenRenewalTimeout = null

const merge = (...objs) => Object.assign.apply(null, [{}, ...objs])
const path = keys => obj => keys.reduce((o, key) => o[key], obj)
const getUserProfile = (a0, accessToken) => {
    return new Promise(function (resolve, reject) {
        a0.client.userInfo(accessToken, (err, profile) => {
            if (err) {
                return reject(err)
            }
            resolve(profile)
        })
    })
}

export default {
    name: 'auth',
    init() {
        auth0 = new WebAuth({
            domain: process.env.AUTH0_DOMAIN,
            clientID: process.env.AUTH0_CLIENTID,
            responseType: 'token id_token',
            scope: 'openid profile admin:on',
            redirectUri: window.location.origin + '/callback',
            audience: 'https://api.citibot.io',
        })
        //auth0.crossOriginVerification();
    },
    doLogin() {
        auth0.authorize()
    },
    doPasswordlessStart(account, phone) {
        return ({ dispatch }) => {
            dispatch({ type: 'AUTH_PASSWORDLESS_START' })
            return new Promise(resolve => {
                auth0.passwordlessStart(
                    {
                        connection: 'sms',
                        send: 'code',
                        phoneNumber: '+' + phone,
                    },
                    (err, res) => {
                        if (err) {
                            console.log({ err })
                            dispatch({ type: 'LOGIN_ERROR', payload: err })
                            return resolve(false)
                        }

                        return resolve(true)
                    }
                )
            })
        }
    },
    doPasswordlessLogin(phone, code) {
        return ({ dispatch }) => {
            dispatch({ type: 'LOGIN_START' })
            return new Promise(resolve => {
                auth0.passwordlessLogin(
                    {
                        connection: 'sms',
                        phoneNumber: '+' + phone,
                        verificationCode: code,
                    },
                    (err, res) => {
                        if (err) {
                            dispatch({ type: 'LOGIN_ERROR', payload: err })
                            return resolve(false)
                        }
                        return resolve(true)
                    }
                )
            })
        }
    },
    doHandleAuthentication() {
        return ({ dispatch, store }) => {
            return new Promise(function (resolve, reject) {
                dispatch({ type: 'LOGIN_STARTED' })
                auth0.parseHash(async (err, authResult) => {
                    if (err) {
                        dispatch({
                            type: 'LOGIN_ERROR',
                            payload: { error: err.errorDescription },
                        })
                        resolve(false)
                        return
                    }
                    // get userInfo
                    let profile = await getUserProfile(
                        auth0,
                        authResult.accessToken
                    )

                    let expiresAt =
                        authResult.expiresIn * 1000 + new Date().getTime()
                    // schedule renew session
                    let delay = expiresAt - Date.now()
                    if (delay > 0) {
                        tokenRenewalTimeout = setTimeout(function () {
                            store.doRenewToken()
                        }, delay)
                    }
                    dispatch({
                        type: 'LOGIN_SUCCESSFUL',
                        payload: {
                            authenticated: true,
                            accessToken: authResult.accessToken,
                            idToken: authResult.idToken,
                            expiresAt,
                            profile,
                        },
                    })
                    console.log('resolve promise')
                    resolve(true)
                })
            })
        }
    },
    doRenewToken() {
        return ({ dispatch, store }) => {
            dispatch({ type: 'LOGIN_STARTED' })
            return new Promise(function (resolve, reject) {
                auth0.checkSession({}, async (err, authResult) => {
                    if (err) {
                        dispatch({
                            type: 'LOGIN_ERROR',
                            payload: { error: err.error },
                        })
                        resolve(false)
                    }

                    // schedule renew
                    window.clearTimeout(tokenRenewalTimeout)
                    let profile = await getUserProfile(
                        auth0,
                        authResult.accessToken
                    )

                    let expiresAt =
                        authResult.expiresIn * 1000 + new Date().getTime()
                    let delay = expiresAt - Date.now()
                    if (delay > 0) {
                        tokenRenewalTimeout = window.setTimeout(function () {
                            console.log('session expired should renew token')
                            //store.doRenewToken()
                        }, delay)
                    }
                    // end schedule
                    dispatch({
                        type: 'LOGIN_SUCCESSFUL',
                        payload: {
                            authenticated: true,
                            accessToken: authResult.accessToken,
                            idToken: authResult.idToken,
                            expiresAt:
                                authResult.expiresIn * 1000 +
                                new Date().getTime(),
                            profile,
                        },
                    })
                    resolve(true)
                })
            })
        }
    },
    doLogout() {
        return ({ dispatch }) => {
            // logout needs to clear scheduled timeout
            clearTimeout(tokenRenewalTimeout)
            dispatch({
                type: 'LOGOUT_SUCCESSFUL',
            })
            return Promise.resolve(true)
        }
    },
    selectIsLoggedIn: path(['auth', 'authenticated']),
    selectAuthError: path(['auth', 'error']),
    selectSessionExpiresAt: path(['auth', 'expiresAt']),
    selectProfile: path(['auth', 'profile']),
    selectAccessToken: path(['auth', 'accessToken']),
    selectIdToken: path(['auth', 'idToken']),
    selectAuthLoading: path(['auth', 'loading']),
    getReducer() {
        const initialState = {
            loading: false,
            authenticated: false,
            profile: null,
            accessToken: null,
            idToken: null,
            expiresAt: null,
        }

        return (state = initialState, { type, payload }) => {
            if (type === 'AUTH_PASSWORDLESS_STARTED') {
                return state
            }
            if (type === 'LOGIN_STARTED') {
                return { loading: true }
            }
            if (type === 'LOGIN_SUCCESSFUL') {
                return merge(state, payload, { loading: false })
            }
            if (type === 'LOGIN_ERROR') {
                return merge(initialState, payload)
            }
            if (type === 'LOGOUT_SUCCESSFUL') {
                return merge(initialState)
            }
            return state
        }
    },
    persistActions: ['LOGIN_SUCCESSFUL'],
}
