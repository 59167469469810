import { composeBundles, createCacheBundle } from "redux-bundler";
import routes from "./routes";
import auth from "./auth-bundle";
import app from "./app";
import account from "./account";
import citizen from "./citizen";
import cache from "../utils/cache";

export default composeBundles(
  routes,
  app,
  auth,
  account,
  citizen,
  createCacheBundle(cache.set)
);
