<script>
    import { getContext, onMount } from 'svelte'

    // states
    // invalid, ready, enter_code, submit, error
    const [READY, CODE, SUBMIT, INVALID, ERROR] = [
        'READY',
        'CODE',
        'SUBMIT',
        'INVALID',
        'ERROR',
    ]
    const store = getContext('store')
    const action = getContext('action')

    let citizen = ''
    let account = ''
    let code = ''
    let state = READY
    let isDisabled = true

    onMount(() => {
        citizen = $store.citizenNo
        account = $store.accountNo
        let number = citizen
        if(number.length === 10){
            number = '1' + number
        }
        // parse query string, if no params then transition to invalid.
        if (or(not(account), account.length >= 12)) {
            // transition to invalid
            state = INVALID
            return
        }
        if(number.length === 11){
            isDisabled = false
        }
        //sendCode()
    })

    async function sendCode() {
        let number = citizen
        if(number.length === 10){
            number = '1' + number
        }
        const res = await action('doPasswordlessStart')(account, number)
        if (!res) {
            return (state = ERROR)
        }
        await action('doSetAccountAndCitizen')(account, number)
        // transition state to code
        state = CODE
    }

    async function login() {
        let number = citizen
        if(number.length === 10){
            number = '1' + number
        }
        const res = await action('doPasswordlessLogin')(number, code)
        if (!res) {
            return (state = ERROR)
        }
        // transition state to submit
        state = SUBMIT
    }

    function onChange(){
        if(citizen.length === 10 || citizen.length === 11){
            isDisabled = false
        }
        else {
            isDisabled = true
        }
    }

    // pure functions
    function equals(a, b) {
        return a === b
    }
    function or(a, b) {
        return a || b
    }
    function length(a, n) {
        return a.length === n
    }
    function not(a) {
        return !a
    }
</script>

<style>
    section {
        height: 100%;
        display: grid;
        grid-template-areas:
            'header'
            'main'
            'footer';

        grid-template-rows: 72px auto 48px;
    }

    header {
        grid-area: header;
    }

    header > div {
        height: 100%;
    }
    .title {
        color: white;
    }

    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    main {
        grid-area: main;
        display: flex;
        flex-direction: column;
    }

    .box {
        width: 350px;
    }

    footer {
        grid-area: footer;
    }
</style>

<section>
    <header class="navbar is-primary center">
        <div class="navbar-brand center">
            <h1 class="title">Citibot Notify</h1>
        </div>
    </header>
    <main class="center">
        <div class="box">
            {#if equals(state, READY)}
                <p>Send 6 digit access code to my phone:</p>
                <div class="field">
                    <label for="phone" class="label">Phone</label>
                    <div class="control">
                        <input
                            id="phone"
                            type="phone"
                            class="input is-primary"
                            bind:value={citizen} 
                            on:input={onChange} />
                    </div>
                </div>
                <button
                    disabled={isDisabled}
                    class="button is-primary"
                    type="button"
                    on:click={sendCode}>
                    Send
                </button>
            {/if}
            {#if equals(state, CODE)}
                <div class="notification is-info">
                    Check your SMS Messages, you should have a six-digit code
                    sent from Citibot Notify.
                </div>
                <div class="field">
                    <div class="control">
                        <label for="code" class="label">Code</label>
                        <input
                            id="code"
                            type="text"
                            class="input is-primary"
                            bind:value={code} />
                    </div>
                </div>
                <button
                    class="button is-primary"
                    type="button"
                    on:click={login}>
                    Login
                </button>
            {/if}
            {#if equals(state, INVALID)}
                <p>Unable to initialize sign in</p>
                <p>
                    Text 'hello' to Citibot and choose citibot notify
                    preferences
                </p>
                <p>
                    If you continue to see this message, contact citibot
                    support: support@citibot.io
                </p>
            {/if}
            {#if equals(state, SUBMIT)}
                <p>Logging In</p>
            {/if}
            {#if equals(state, ERROR)}
                <p>It appears the code your entered does not match</p>
                <p>Please try entering the code again</p>
                <button
                    class="button is-primary"
                    type="button"
                    on:click={() => (state = CODE)}>
                    Try Again
                </button>
            {/if}
        </div>
    </main>
    <footer class="center">
        <p>All rights reserved. &copy; Citibot, LLC 2020</p>
    </footer>
</section>
