import { createRouteBundle } from "redux-bundler";

import Start from "../pages/Start.svelte";
import About from "../pages/About.svelte";
import Preferences from "../pages/Preferences.svelte";
import Login from "../pages/Login.svelte";
import Auth from "../pages/Auth.svelte";
import Thanks from "../pages/Thanks.svelte";

export default createRouteBundle({
  "/": Start,
  "/preferences": Preferences,
  "/about": About,
  "/login": Login,
  "/callback": Auth,
  "/thanks": Thanks
});
